<template>
  <section class="content">
  <div class="btn-box">
    <el-button type="primary" size="mini" @click="add">添加商品</el-button>
  </div>
  <el-table
    v-loading="loading"
    :data="list"
    border>
    <el-table-column label="序号" prop="id"></el-table-column>
    <el-table-column label="商品Id" prop="item_id"></el-table-column>
    <el-table-column label="添加时间">
      <template slot-scope="scope">
        {{scope.row.create_time | dateTime}}
      </template>
</el-table-column>
<el-table-column label="操作">
    <template slot-scope="scope">
      <el-button type="danger" size="mini" @click="del(scope.row.id)" >删除</el-button>
    </template>
</el-table-column>
</el-table>
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange"></el-pagination>
<!-- 关联商品 -->
<el-dialog title="关联商品" :visible.sync="shopDialog" :close-on-click-modal=false>
    <el-form>
        <el-form-item label="商品ID">
            <el-input v-model="item_id" placeholder="请输入商品ID"></el-input>
        </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button @click="shopDialog = false">取 消</el-button>
        <el-button type="primary" @click="relevanceItem">确 定</el-button>
    </div>
</el-dialog>
</section>
</template>
<script>
    import * as api from '@/config/api'
    export default {
        name: 'DoubleElevenData',
        data() {
            return {
                loading: true,
                page: 1,
                pagesize: 10,
                total: 10,

                shopDialog: false,
                list: [],

                item_id: '',

                detail: {},
            }
        },
        filters: {
            status(val) {
                let name
                switch (Number(val)) {
                    case 1:
                        name = '积累中'
                        break;
                    case 2:
                        name = '已开启'
                        break;
                    case 3:
                        name = '开启失败'
                        break;
                    case 4:
                        name = '开启成功'
                        break;
                    default:
                        name = ""
                        break;
                }
                return name
            },
            dateTime(val) {
                let day = new Date(Number(val) * 1000)
                if (Number(val) === 0) {
                    return ''
                }
                return day.getFullYear() + '-' + `${(day.getMonth() + 1) < 10 ? '0' + (day.getMonth() + 1) : (day.getMonth() + 1)}` + '-' + `${day.getDate()<10?'0'+day.getDate():day.getDate()}` + ' ' + `${day.getHours()<10?'0'+day.getHours():day.getHours()}` + ':' + `${day.getMinutes()<10?'0'+day.getMinutes():day.getMinutes()}` + ':' + `${day.getSeconds()<10?'0'+day.getSeconds():day.getSeconds()}`
            }
        },
        mounted() {
            // this.getAssociationDetail()
            this.getDoubleElevenActData()
        },
        methods: {
            init() {

            },
            getAssociationDetail() {
                api.getAssociationDetail({
                    id: this.$route.query.id
                }, res => {
                    this.detail = res.data.row
                })
            },
            getDoubleElevenActData(page, size) {
                api.getDoubleElevenActData({
                    community_id: this.$route.query.id,
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize
                }, res => {
                    this.list = res.data.list
                    this.total = Number(res.data.count)
                    this.loading = false
                })
            },
            sizeChange(val) {
                this.pagesize = val
                this.getDoubleElevenActData()
            },
            currentChange(val) {
                this.page = val
                this.getDoubleElevenActData()
            },
            add() {
                this.item_id = ''
                this.shopDialog = true
            },
            relevanceItem() {
                api.addDoubleElevenActShop({
                    community_id: this.$route.query.id,
                    item_id: this.item_id,
                }, res => {
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                    this.shopDialog = false
                    this.getDoubleElevenActData()
                })
            },
            del(id) {
                this.$confirm('确认要删除该商品吗', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    api.delDoubleElevenActShop({
                        id: id
                    }, res => {
                        this.$message({
                            type: 'success',
                            message: '删除成功'
                        });
                        this.getDoubleElevenActData()
                    })
                }).catch(() => {})
            }
        }
    }
</script>
<style lang="scss">
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    .el-form-item {
        text-align: left;
    }
    
    .detail-box {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        background-color: #fff;
        margin-bottom: 10px;
        padding: 10px;
        .detail {
            width: 70%;
            div {
                width: calc(100%/3);
                display: inline-block;
                float: left;
            }
        }
        .status {
            width: 15%;
        }
    }
</style>